exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-ctsi-token-tsx": () => import("./../../../src/pages/ctsi-token.tsx" /* webpackChunkName: "component---src-pages-ctsi-token-tsx" */),
  "component---src-pages-developers-tsx": () => import("./../../../src/pages/developers.tsx" /* webpackChunkName: "component---src-pages-developers-tsx" */),
  "component---src-pages-governance-tsx": () => import("./../../../src/pages/governance.tsx" /* webpackChunkName: "component---src-pages-governance-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-linux-tsx": () => import("./../../../src/pages/linux.tsx" /* webpackChunkName: "component---src-pages-linux-tsx" */),
  "component---src-pages-podcast-tsx": () => import("./../../../src/pages/podcast.tsx" /* webpackChunkName: "component---src-pages-podcast-tsx" */),
  "component---src-pages-simple-tsx": () => import("./../../../src/pages/simple.tsx" /* webpackChunkName: "component---src-pages-simple-tsx" */),
  "component---src-pages-technical-evolution-plan-tsx": () => import("./../../../src/pages/technical-evolution-plan.tsx" /* webpackChunkName: "component---src-pages-technical-evolution-plan-tsx" */),
  "component---src-pages-usecases-tsx": () => import("./../../../src/pages/usecases.tsx" /* webpackChunkName: "component---src-pages-usecases-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blogArticle.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blogTag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-podcast-article-tsx": () => import("./../../../src/templates/podcastArticle.tsx" /* webpackChunkName: "component---src-templates-podcast-article-tsx" */),
  "component---src-templates-podcast-tag-tsx": () => import("./../../../src/templates/podcastTag.tsx" /* webpackChunkName: "component---src-templates-podcast-tag-tsx" */)
}

